import React, { ReactNode } from "react";

import { Box, Container, Flex } from "@chakra-ui/react";
import { Nav } from "ui/Nav";

export const OnboardingLayout: React.FC<{ children?: ReactNode }> = ({
  children,
}) => (
  <Box minH="100vh" display="flex" flexDirection="column">
    <Nav />
    <Flex grow={1} direction="column" justify="center">
      <Container maxWidth={1000}>{children}</Container>
    </Flex>
  </Box>
);
