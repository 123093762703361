import React from "react";

import {
  Alert,
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
  Link as ChakraLink,
  Radio,
  RadioGroup,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { CompleteFormData } from "forms/useFormCreateAccountWithStripe";
import { OnboardingLayout } from "layouts/Onboarding";
import capitalize from "lodash/capitalize";
import Link from "next/link";
import { Controller, UseFormReturn } from "react-hook-form";
import { CompanyStructureOptions } from "typings/shared";
import { Input } from "ui/Input";

export function IndexPageView({
  errorMessage,
  form,
  onSubmit,
}: {
  errorMessage?: string;
  form: UseFormReturn<CompleteFormData>;
  onSubmit: (formData: CompleteFormData) => void;
}) {
  const [showPassword, setShowPassword] = useBoolean();
  const [accessCode, setAccessCode] = React.useState("");

  return (
    <OnboardingLayout>
      <Box
        bg="#fcfcfc"
        rounded="md"
        boxShadow="md"
        p={8}
        borderColor="#eee"
        borderWidth="1px"
        maxW="500px"
        mx="auto"
      >
        <Heading size="lg" textAlign="center" mb={10}>
          Sign up
        </Heading>
        {errorMessage && (
          <Alert mb={8} colorScheme="red">
            {errorMessage}
          </Alert>
        )}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Flex gap={5}>
            <Input
              name="email"
              placeholder="Email"
              hookForm={form}
              bg="white"
            />
            <Input
              type="text"
              name="access_code"
              placeholder="Access code"
              width="100%"
              bg="white"
              hookForm={form}
              maxLength={6}
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value.toUpperCase())}
            />
          </Flex>
          <InputGroup>
            <Input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              bg="white"
              hookForm={form}
            />
            <InputRightElement>
              <Button
                size="sm"
                mr="6"
                px="6"
                height="20px"
                fontSize="12px"
                colorScheme="brand"
                onClick={setShowPassword.toggle}
              >
                {showPassword ? "hide" : "show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Flex gap={5}>
            <Flex flex={1}>
              <Input
                name="first_name"
                placeholder="First name"
                width="100%"
                bg="white"
                hookForm={form}
              />
            </Flex>
            <Flex flex={1}>
              <Input
                name="last_name"
                bg="white"
                placeholder="Last name"
                width="100%"
                hookForm={form}
              />
            </Flex>
          </Flex>

          <Flex gap={5}>
            <Flex flex={2}>
              <Input
                name="billing_address_line_1"
                placeholder="Street address"
                bg="white"
                hookForm={form}
              />
            </Flex>
            <Flex flex={1}>
              <Input
                name="billing_address_line_2"
                placeholder="Unit"
                bg="white"
                hookForm={form}
              />
            </Flex>
          </Flex>
          <Flex gap={5}>
            <Flex gap="5" flex={2}>
              <Flex flex={1}>
                <Input
                  name="billing_address_city"
                  placeholder="City"
                  width="100%"
                  bg="white"
                  hookForm={form}
                />
              </Flex>
              <Flex flex={1}>
                <Input
                  name="billing_address_state"
                  placeholder="State"
                  maxLength={2}
                  width="100%"
                  bg="white"
                  hookForm={form}
                />
              </Flex>
            </Flex>

            <Flex flex={1}>
              <Input
                name="billing_address_postal_code"
                placeholder="Zip"
                maxLength={5}
                width="100%"
                bg="white"
                hookForm={form}
              />
            </Flex>
          </Flex>
          <Flex direction="column">
            <FormLabel fontSize="14px" color="gray.400" mb={2.5}>
              Company Structure
            </FormLabel>
            <Controller
              name="company_structure"
              control={form.control}
              render={({ field: { onChange, value } }: any) => (
                <RadioGroup
                  defaultValue={CompanyStructureOptions.SoleProprietorship}
                  onChange={onChange}
                  value={value}
                  mb={5}
                  as={Flex}
                  flexWrap="wrap"
                  // justifyContent="space-evenly"
                >
                  {Object.values(CompanyStructureOptions).map((option) => {
                    return (
                      <Radio
                        key={option}
                        value={option}
                        flexBasis={{ base: "100%", md: "50%" }}
                        mb={2.5}
                        colorScheme="brand"
                      >
                        {option
                          .split("_")
                          .map((word) =>
                            word === "llc"
                              ? word.toUpperCase()
                              : capitalize(word),
                          )
                          .join(" ")}
                      </Radio>
                    );
                  })}
                </RadioGroup>
              )}
            />
          </Flex>
          <Button
            colorScheme="brand"
            type="submit"
            disabled={!form.formState.isValid}
            display="block"
            w="100%"
          >
            Submit
          </Button>
        </form>
        <Text textAlign="center" color="gray" mt={5}>
          Already have an account? {` `}
          <Link href="/login" passHref>
            <ChakraLink color="brand.500">Log in here</ChakraLink>
          </Link>
        </Text>
      </Box>
    </OnboardingLayout>
  );
}
