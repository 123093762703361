import * as Yup from "yup";

export const IdRequired = Yup.number().positive().required("Required");

export const USStateCode = Yup.string()
  .min(2, "")
  .max(2, "")
  .required("Required")
  .matches(
    /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|PR|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/i,
    "Invalid State",
  );
export const USStateCodeRequired = USStateCode;

export const USZip = Yup.string()
  .min(5, "")
  .max(5, "")
  .required("Required")
  .matches(/^[0-9]+$/, "Invalid Zip");
export const USZipRequired = USZip;

export const routingNumber = Yup.string()
  .min(9, "")
  .max(9, "")
  .required("Required")
  .matches(/^[0-9]+$/, "Invalid Routing Number");
export const routingNumberRequired = routingNumber;

export const accountNumber = Yup.string()
  .min(6, "")
  .max(17, "")
  .required("Required")
  .matches(/^[0-9]+$/, "Invalid Account Number");
export const accountNumberRequired = accountNumber;

export const amount = Yup.string()
  .test("", "", (value) => !!value?.replace(/[^\d.-]/g, "").length)
  .required("Required");

export const password = Yup.string()
  .matches(
    /(?=[A-Za-z0-9@#$%^&+!=*-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=*-])(?=.{8,}).*$/,
    'Your password must contain at least 8 characters and at least: 1 Lower case letter (a-z), 1 Upper case letter (A-Z), 1 Number (0-9), and 1 Special character (ex. !@#$%^&*-)"',
  )
  .min(8, "");
export const passwordRequired = password.required("Required");

// eslint-disable-next-line import/no-default-export
export default {
  IdRequired,
  USStateCode,
  USStateCodeRequired,
  USZip,
  USZipRequired,
  amount,
  password,
  passwordRequired,
};
