import { routes } from "network/routes";
import { useHfMutation } from "network/useHfMutation";
import {
  AccessCode,
  CompanyStructure,
  Email,
  AccountAddress,
  WelcomeFlowStep,
  Name,
  Password,
  HostfiUser,
} from "typings/shared";

export type CreateAccountInput = Email &
  Password &
  Name &
  AccessCode &
  AccountAddress & {
    include_stripe_account_link: boolean;
    refresh_url_path: "welcome";
    return_url_path: "return";
  } & CompanyStructure;

export type TStripeAccountLink = {
  url: string;
  created: number | string;
  expires_at: number | string;
  object: string;
};

export type TCreateAccountRes = {
  account: AccountAddress & {
    id: number | string;
    created_at_utc: string;
    deleted_at_utc: string;
    stripe_account_id: string;
    stripe_financial_account_id: string;
    stripe_requirements_completed: boolean;
    updated_at_utc: string;
    uuid: string;
    remaining_requirements: WelcomeFlowStep[];
  };
  account_link: TStripeAccountLink;
  user: HostfiUser;
  token: { access_token: string };
};

export const useCreateUser = () =>
  useHfMutation<CreateAccountInput, TCreateAccountRes>(routes.accountCreate);
