import React, { useEffect } from "react";

import { Box, Flex, Heading, Spinner } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { isBrowser } from "utils/env";

const DURATION = 0.33;
const DURATION_MS = 0.33 * 1000;

export function LoaderSignup({ visible = false }: { visible: boolean }) {
  useEffect(() => {
    if (isBrowser) {
      if (visible) {
        document.body.classList.add("fixed");
        window.scrollTo(0, 0);
      } else {
        setTimeout(() => {
          document.body.classList.remove("fixed");
        }, DURATION_MS);
      }
    }
  }, [visible]);

  useEffect(() => () => document.body.classList.remove("fixed"), []);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        type: "linear",
        delayChildren: DURATION,
        staggerChildren: DURATION * 2,
        ease: "easeOut",
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 50 },
    show: {
      opacity: 1,
      y: 0,
    },
  };

  const longItem = {
    hidden: { opacity: 0, y: 50 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 7,
        duration: DURATION * 2,
        ease: "easeOut",
      },
    },
  };

  if (!visible) {
    return <AnimatePresence />;
  }

  return (
    <AnimatePresence>
      <Box
        as={motion.div}
        variants={container}
        initial="hidden"
        animate="show"
        exit="hidden"
        height="100vh"
        width="100vw"
        overflow="scroll"
        display="flex"
        alignItems="center"
        justifyContent={{ base: "", md: "center" }}
        bg="#300606"
        position="absolute"
        zIndex="40"
        color="white"
        flexDirection="column"
      >
        <Box as={motion.div} variants={item}>
          <Spinner
            thickness="3px"
            speed="0.75s"
            emptyColor="#DDD"
            color="#300606"
            width="2.6rem"
            height="2.6rem"
            top={0}
            left={0}
            my="10"
          />
        </Box>

        <Heading
          size="md"
          as={motion.div}
          variants={item}
          lineHeight="1.25"
          fontWeight="normal"
        >
          Thanks for signing up with Hostfi!
          <br />
          Here are the 3 onboarding steps.
        </Heading>

        <Flex
          gap="5"
          mt="10"
          w="850px"
          maxW="100%"
          justifyContent="space-evenly"
          flexDirection={{ base: "column", md: "row" }}
          flexWrap="wrap"
          p="5"
        >
          <Box
            as={motion.div}
            variants={item}
            border="1px solid white"
            borderRadius="5"
            p="5"
            whiteSpace="nowrap"
            textAlign="center"
            w={{ base: "100%", md: "18%" }}
          >
            <Box as="img" src="/images/white-avatar.svg" my="5" mx="auto" />
            Confirm identity
          </Box>

          <Box
            as={motion.div}
            variants={item}
            border="1px solid white"
            borderRadius="5"
            p="5"
            whiteSpace="nowrap"
            textAlign="center"
            w={{ base: "100%", md: "18%" }}
          >
            <Box as="img" src="/images/white-bank.svg" my="5" mx="auto" />
            Link account
          </Box>

          <Box
            as={motion.div}
            variants={item}
            border="1px solid white"
            borderRadius="5"
            p="5"
            whiteSpace="nowrap"
            textAlign="center"
            w={{ base: "100%", md: "18%" }}
          >
            <Box as="img" src="/images/white-db.svg" my="5" mx="auto" />
            Add funds
          </Box>
        </Flex>

        <Heading
          size="md"
          as={motion.div}
          variants={longItem}
          lineHeight="1.25"
          fontWeight="normal"
          my="10"
        >
          Identity confirmation will begin shortly.
        </Heading>
      </Box>
    </AnimatePresence>
  );
}
