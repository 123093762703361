import React, { LegacyRef } from "react";

import {
  Box,
  Input as ChakraInput,
  InputProps,
  SlideFade,
  Text,
} from "@chakra-ui/react";
import get from "lodash/get";
import omit from "lodash/omit";
import toNumber from "lodash/toNumber";
import { UseFormRegisterReturn, UseFormReturn } from "react-hook-form";

export interface MergedInputProps extends InputProps {
  hideError?: boolean;
  hookForm?: UseFormReturn<any, any>;
  isMaskedInput?: boolean;
  handleMaskedInputChange?: (value: any) => void;
}

export const Input = React.forwardRef(
  (props: MergedInputProps, ref: LegacyRef<HTMLInputElement>) => {
    const { name, hookForm, isMaskedInput } = props;
    const passThroughProps = omit(props, [
      "hookForm",
      "isMaskedInput",
      "hideError",
      "handleMaskedInputChange",
    ]);
    const { formState } = hookForm || {};
    const register =
      name && hookForm?.register
        ? hookForm.register(name)
        : ({} as UseFormRegisterReturn<string>);

    const errorMessage: string =
      formState?.errors && name
        ? (get(formState.errors, name)?.message as string)
        : "";

    return (
      <Box width="100%">
        <ChakraInput
          size="md"
          {...passThroughProps}
          {...register}
          ref={!!register?.ref && !isMaskedInput ? register?.ref : ref}
          onChange={(e) => {
            if (name && hookForm) {
              const value =
                passThroughProps.type === "number"
                  ? toNumber(e.target.value)
                  : e.target.value;
              hookForm.setValue(name, value, {
                shouldValidate: true,
              });
            }
            if (register.onChange) register.onChange(e);
            if (props.onChange) props.onChange(e);
          }}
          isInvalid={!!errorMessage}
          _placeholder={{ opacity: 1, color: "gray.400" }}
        />
        {!props?.hideError && (
          <Box minHeight="17px" my="5px">
            <SlideFade offsetY="5px" in={!!errorMessage}>
              <Text
                as="small"
                fontSize="11px"
                color="brand.500"
                display="block"
              >
                {errorMessage}
              </Text>
            </SlideFade>
          </Box>
        )}
      </Box>
    );
  },
);
