import React, { useState } from "react";

import {
  CompleteFormData,
  useFormCreateAccountWithStripe,
} from "forms/useFormCreateAccountWithStripe";
import { useCreateUser } from "network/Onboarding/createAccount";
import { LoaderSignup } from "ui/Loader/signup";
import { setAccessToken } from "utils/auth";
import { navigateExternal } from "utils/navigateExternal";
import { IndexPageView } from "views/pages/index";

function getErrorMessage(error: any) {
  const { message = "", name = "" } = error?.response?.data?.error || {};
  if (name === "IntegrityError") {
    return "There was an issue with the information submitted. Please contact us if the problem persists.";
  }
  if (message === "Service Unavailable") {
    return message;
  }

  return message;
}

export default function () {
  const form = useFormCreateAccountWithStripe();
  const createUser = useCreateUser();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleSubmit = function handleSubmit(formData: CompleteFormData) {
    setIsLoading(true);
    createUser.mutate(
      {
        email: formData.email,
        password: formData.password,
        first_name: formData.first_name,
        last_name: formData.last_name,
        access_code: formData.access_code.toUpperCase(),
        billing_address_line_1: formData.billing_address_line_1,
        billing_address_line_2: formData.billing_address_line_2,
        billing_address_city: formData.billing_address_city,
        billing_address_state: formData.billing_address_state,
        billing_address_postal_code: formData.billing_address_postal_code,
        company_structure: formData.company_structure,
        include_stripe_account_link: true,
        refresh_url_path: "welcome",
        return_url_path: "return",
      },
      {
        onSuccess: (response) => {
          // timeout seems to be needed for Stripe Connect redirect to work
          setTimeout(() => {
            setAccessToken(response.token.access_token);
            navigateExternal(response.account_link.url);
          }, 500);
        },
        onError: (err: any) => {
          setErrorMessage(getErrorMessage(err));
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <>
      <LoaderSignup visible={isLoading} />
      <IndexPageView
        form={form}
        onSubmit={handleSubmit}
        errorMessage={errorMessage}
      />
    </>
  );
}
