import React from "react";

import { Box, Container, Flex } from "@chakra-ui/react";
import { NavButton } from "ui/Buttons";
import { HostFiLogo } from "ui/Nav/HostFiLogo";

export function Nav() {
  return (
    <Box
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={5}
      position="absolute"
    >
      <Container maxWidth={1000}>
        <Flex
          as="nav"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth={1000}
        >
          <Flex alignItems="center">
            <HostFiLogo />
          </Flex>
          <NavButton />
        </Flex>
      </Container>
    </Box>
  );
}
