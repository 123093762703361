import { yupResolver } from "@hookform/resolvers/yup";
import validation from "forms/validation";
import { useForm } from "react-hook-form";
import {
  AccessCode,
  CompanyStructure,
  CompanyStructureOptions,
  Email,
  Name,
  Password,
  AccountAddress,
} from "typings/shared";
import * as Yup from "yup";

export type CompleteFormData = AccessCode &
  Email &
  Name &
  Password &
  AccountAddress &
  CompanyStructure;

const addressSchema: Yup.SchemaOf<CompleteFormData> = Yup.object({
  access_code: Yup.string().required("Required"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  password: validation.passwordRequired,
  email: Yup.string().required("Required"),
  billing_address_line_1: Yup.string().required("Required"),
  billing_address_line_2: Yup.string(),
  billing_address_city: Yup.string().required("Required"),
  billing_address_state: validation.USStateCodeRequired,
  billing_address_postal_code: validation.USZip,
  company_structure: Yup.mixed().oneOf(Object.values(CompanyStructureOptions)),
});

export const useFormCreateAccountWithStripe = () =>
  useForm<CompleteFormData>({
    mode: "all",
    resolver: yupResolver(addressSchema),
    defaultValues: {
      company_structure: CompanyStructureOptions.SoleProprietorship,
    },
  });
